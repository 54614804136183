import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Grid from '@material-ui/core/Grid';
// import AuthBanner from './AuthBanner';
import Login from './Login';
import SignUp from './SignUp';

function LoginPage() {
  const [registerMode, toggleRegisterMode] = useState<boolean>(false);

  return (
    <>
      <Helmet>
        <title>{registerMode ? 'Sign Up' : 'Login'}</title>
        <meta
          name="description"
          content={`WeWork On Demand B2B - ${
            registerMode ? 'Sign Up' : 'Login'
          }`}
        />
      </Helmet>
      <Grid container justify="center">
        <Grid item md={6} xs={12}>
          {registerMode ? (
            <SignUp toggleRegisterMode={toggleRegisterMode} />
          ) : (
            <Login toggleRegisterMode={toggleRegisterMode} />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default LoginPage;
