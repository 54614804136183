import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useAuthContext } from '../../utils/hooks/useAuthContext';
import { makeStyles, Theme } from '@material-ui/core/styles';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  successToast: {
    backgroundColor: '#12872A',
    color: '#FFFFFF',
  },
  errorToast: {
    backgroundColor: '#FF0000',
    color: '#FFFFFF',
  },
  infoToast: {
    backgroundColor: '#0000FF',
    color: '#FFFFFF',
  },
  warnToast: {
    backgroundColor: '#FFD500',
    color: '#000000',
  },
}));

function ToastAlert() {
  const { toastType, setToastType } = useAuthContext()!;
  const classes = useStyles();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastType({ show: false, type: toastType.type, message: '' });
  };
  const handleToastType = (type: string) => {
    switch (type) {
      case 'success':
        return classes.successToast;
      case 'error':
        return classes.errorToast;
      case 'info':
        return classes.infoToast;
      case 'warning':
        return classes.warnToast;
      default:
        return classes.successToast;
    }
  };

  return (
    <Snackbar
      open={toastType.show}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        className={handleToastType(toastType.type)}
        onClose={handleClose}
        severity={toastType.type}
      >
        {toastType.message}
      </Alert>
    </Snackbar>
  );
}

export default ToastAlert;
