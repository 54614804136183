import Grid from '@material-ui/core/Grid';
import RayBodyText from 'src/components/RayTypography/Body';
import { format as dateFormat } from 'date-fns';
import { SalesRepresentative } from './types';

type Props = {
  selectedRep: SalesRepresentative;
};

function RepInfo({ selectedRep }: Props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <RayBodyText>
          <b>Rep Name: </b>
          {selectedRep.name}
        </RayBodyText>
      </Grid>
      <Grid item xs={6}>
        <RayBodyText>
          <b>Created On: </b>
          {selectedRep.created_at && selectedRep.created_at._seconds
            ? dateFormat(
                new Date(selectedRep.created_at._seconds * 1000),
                'do MMM, yyy',
              )
            : '--'}
        </RayBodyText>
      </Grid>
      <Grid item xs={6}>
        <RayBodyText style={{ textTransform: 'capitalize' }}>
          <b>Role: </b>
          {selectedRep.role ? selectedRep.role : '--'}
        </RayBodyText>
      </Grid>
      <Grid item xs={6}>
        <RayBodyText>
          <b>Rep Email: </b>
          {selectedRep.email ? selectedRep.email : '--'}
        </RayBodyText>
      </Grid>
      <Grid item xs={6}>
        <RayBodyText>
          <b>Organisation: </b>
          {selectedRep.organization && selectedRep.organization.name
            ? selectedRep.organization.name
            : '--'}
        </RayBodyText>
      </Grid>
      <Grid item xs={6}>
        <RayBodyText>
          <b>Created by (Email): </b>
          {selectedRep.created_by ? selectedRep.created_by : '--'}
        </RayBodyText>
      </Grid>
    </Grid>
  );
}

export default RepInfo;
