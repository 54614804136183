import React, { createContext, useState, useEffect } from 'react';
import firebase from '../firebase';
import { fetchUserProfileInfo } from '../functions/fetchUserProfile';
import { ContextStructure, ToastStructure, UserInformation } from './types';

type Props = {
  children: React.ReactNode;
};

const UserContext = createContext<ContextStructure | undefined>(undefined);

export const UserProvider = ({ children }: Props) => {
  const [userInformation, setUserInformation] =
    useState<UserInformation | null>(null);
  const [tokenLoader, setTokenLoader] = useState(true);
  const [toastType, setToastType] = useState<ToastStructure>({
    show: false,
    type: undefined,
    message: '',
  });

  useEffect(() => {
    firebase.auth.onAuthStateChanged(async user => {
      if (user) {
        setTokenLoader(true);
        const tokenVal = await user.getIdToken(true);
        if (tokenVal) {
          const userProfileInfo = await fetchUserProfileInfo(
            tokenVal,
            user?.email ? user?.email : '',
          );
          setUserInformation({ ...userProfileInfo, id: user.uid });
          setTokenLoader(false);
        }
      } else {
        setUserInformation(null);
        setTokenLoader(false);
      }
    });
  }, []);

  const retrieveToken = async (): Promise<string | null> => {
    const userInstance = firebase.auth.currentUser;
    const token = userInstance && (await userInstance.getIdToken(true));
    return token;
  };

  return (
    <UserContext.Provider
      value={{
        userInfo: userInformation,
        tokenLoad: tokenLoader,
        setUserInfo: setUserInformation,
        retrieveToken: retrieveToken,
        toastType: toastType,
        setToastType: setToastType,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const UserConsumer = UserContext.Consumer;

export default UserContext;
