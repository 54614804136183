import { Dispatch } from 'react';
export interface LoginFormData {
  userEmail: string;
  password: string;
}
export interface SignUpFormData {
  userEmail: string;
  password: string;
  confirmPassword: string;
}

export const ERROR_MESSAGES_FIREBASE = {
  'auth/invalid-email': 'Please enter a valid email address',
  'auth/user-not-found':
    'We couldn’t find a user with that email address. Please create an account.',
  'auth/wrong-password':
    'The email/password entered was incorrect. Please try again.',
};

export type AuthPageType = {
  toggleRegisterMode: Dispatch<boolean>;
};
