import axios from '../axios';

async function fetchUserProfileInfo(token: string, email: string) {
  try {
    const request = await axios.get(`b2b/sales-demo/users`, {
      headers: { Authorization: token },
      params: { email },
    });
    return request.data.user;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

export { fetchUserProfileInfo };
