import React, { useState } from 'react';
import styled from 'styled-components/macro';

type InputProps = {
  type?: React.InputHTMLAttributes<unknown>['type'];
  placeholder?: string;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  onClick?:
    | ((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void)
    | undefined;
  label?: string;
  name?: string;
  inputRef?:
    | string
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;
  style?: React.CSSProperties;
  id?: string;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  defaultValue?: string | number;
  value?: string | number | readonly string[] | undefined;
  autoMargin?: boolean;
};

const Input = ({
  id,
  name,
  type = 'text',
  placeholder,
  onChange,
  onClick,
  label,
  style,
  inputRef,
  className,
  value,
  defaultValue,
  fullWidth = false,
  autoMargin = true,
  disabled = false,
  ...rest
}: InputProps) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <div className={`ray-text-field ${className}`}>
      <input
        id={id}
        onChange={onChange}
        name={name}
        defaultValue={defaultValue}
        disabled={disabled}
        onClick={onClick}
        className="ray-text-field__input"
        ref={inputRef}
        type={type === 'password' ? (show ? 'text' : 'password') : type}
        placeholder={placeholder}
        style={style}
        value={value}
        {...rest}
      />
      <label className="ray-text-field__label" htmlFor={id}>
        {label}
      </label>
      {type === 'password' && (
        <button
          type="button"
          onClick={() => setShow(!show)}
          className="ray-button ray-button--tertiary"
        >
          {show ? 'Hide' : 'Show'}
        </button>
      )}
    </div>
  );
};

const InputWrapper = styled(Input)`
  ${props => (props.autoMargin ? 'margin: 0.5em;' : '')}
  width: ${props => (props.fullWidth ? '100%' : '20%')} !important;
`;

export default InputWrapper;
