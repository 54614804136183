import React from 'react';
import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useAuthContext } from 'src/utils/hooks/useAuthContext';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import RayInput from 'src/components/RayInput';
import axios from 'src/utils/axios';
import RayButton from 'src/components/RayButton';
import MaterialSelect from 'src/components/MaterialSelect';
import { USER_TYPE_OPTIONS, UserCreationRequestBody } from './types';

type Props = {
  toggleCreateUserDialog: React.Dispatch<boolean>;
};

const fetchSalesOrgs = () =>
  axios.get('/b2b/sales-demo/sales-organizations').then(res => res.data);
const adminUserCreate = (requestBody: UserCreationRequestBody) =>
  axios.post('/b2b/sales-demo/admin/users', requestBody).then(res => res.data);

function CreateRepresentative({ toggleCreateUserDialog }: Props) {
  const {
    isLoading,
    error,
    data: salesOrganisations,
  } = useQuery('salesOrgs', fetchSalesOrgs);

  const { setToastType, userInfo } = useAuthContext()!;

  const [emailAddress, setEmailAddress] = useState<string | undefined>();
  const [fullName, setFullName] = useState<string | undefined>();
  const [selectedOrganisation, setSelectedOrganisation] = useState<string>('');
  const [selectedOrganisationId, setSelectedOrganisationId] =
    useState<string>('');
  const [userType, setUserType] = useState<string>('');

  const queryClient = useQueryClient();
  const { isLoading: mutationLoader, mutate } = useMutation(adminUserCreate, {
    onSuccess: data => {
      queryClient.invalidateQueries('salesReps');
      setToastType({ show: true, message: 'User Added', type: 'success' });
      toggleCreateUserDialog(false);
    },
    onError: error => {
      console.log(error);
      setToastType({
        show: true,
        message: 'Error occurred while adding user',
        type: 'error',
      });
    },
  });

  const createSalesRep = async () => {
    if (!emailAddress || !fullName || !selectedOrganisationId || !userType) {
      setToastType({
        show: true,
        message: 'Please fill all the details',
        type: 'warning',
      });
      return;
    }
    if (emailAddress && emailAddress.length < 3) {
      setToastType({
        show: true,
        message: 'Email Address should be greater than 3 characters',
        type: 'warning',
      });
      return;
    }
    const body = {
      user_name: fullName || '',
      user_email: emailAddress || '',
      organization_name: selectedOrganisation || '',
      organization_id: selectedOrganisationId || '',
      role: userType || '',
      created_by: userInfo?.email || '',
    };
    mutate(body);
  };
  const getOrganisationName = (value: string) =>
    salesOrganisations.sales_organizations.find(type => value === type.id);
  const handleChangeOrganisation = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode,
  ) => {
    console.log(event);
    if (event.target.value === null) {
      setSelectedOrganisation('');
      setSelectedOrganisationId('');
    } else {
      setSelectedOrganisation(
        getOrganisationName(String(event.target.value)).name,
      );
      setSelectedOrganisationId(String(event.target.value));
    }
  };
  const handleChangeEmployeeType = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode,
  ) => {
    if (event.target.value === null) {
      setUserType('');
    } else {
      setUserType(String(event.target.value));
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <RayInput
          id="full_name"
          fullWidth
          label="Full Name"
          onChange={({ target }) => setFullName(target.value)}
          placeholder="Arya Stark"
          name="full-name"
        />
      </Grid>
      <Grid item xs={6}>
        <RayInput
          id="email_address"
          fullWidth
          label="Email Address"
          onChange={({ target }) => setEmailAddress(target.value)}
          placeholder="arya.stark@wework.co.in"
          name="email-address"
        />
      </Grid>
      <Grid item xs={6}>
        <MaterialSelect
          fullWidth
          name="Organisations List"
          label="Organisations List"
          disabled={isLoading || error ? true : false}
          options={
            salesOrganisations &&
            salesOrganisations.sales_organizations &&
            salesOrganisations.sales_organizations.length > 0
              ? salesOrganisations.sales_organizations.map(org => ({
                  name: org.name,
                  value: org.id,
                }))
              : []
          }
          onChange={handleChangeOrganisation}
          value={selectedOrganisationId}
        />
      </Grid>

      <Grid item xs={6}>
        <MaterialSelect
          fullWidth
          name="user__type"
          label="Type of User"
          options={USER_TYPE_OPTIONS}
          onChange={handleChangeEmployeeType}
          value={userType}
        />
      </Grid>
      <Grid item xs={12} spacing={0} container justify="flex-end">
        <Grid item xs={3}>
          <RayButton
            disabled={mutationLoader}
            onClick={createSalesRep}
            fullWidth
          >
            {mutationLoader ? (
              <CircularProgress size={15} style={{ color: '#fff' }} />
            ) : (
              'Create'
            )}
          </RayButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CreateRepresentative;
