export interface SalesRepresentative {
  created_at: CreatedAt;
  type: string;
  organization: Organization;
  name: string;
  role: string;
  created_by: string;
  email: string;
}

export interface CreatedAt {
  _seconds: number;
  _nanoseconds: number;
}

export interface Organization {
  name: string;
  id: string;
}

export const USER_TYPE_OPTIONS = [
  { name: 'Admin', value: 'admin' },
  { name: 'Employee', value: 'employee' },
];

export interface UserCreationRequestBody {
  user_name: string;
  user_email: string;
  organization_name: string;
  organization_id: string;
  role: string;
  created_by: string;
}
