import { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components/macro';
import { useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { useAuthContext } from 'src/utils/hooks/useAuthContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import RayInput from 'src/components/RayInput';
import axios from 'src/utils/axios';
import { H6 } from 'src/components/RayTypography/Heading';
import RayBodyText from 'src/components/RayTypography/Body';
import RayButton from 'src/components/RayButton';
import firebase from 'src/utils/firebase';
import {
  CreateDemoProps as Props,
  CreateDemoRequestBody,
  isErrorType,
  CreateDemoForm,
} from '../types';

const FormWrapper = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const getStorageErrorMessage = (error: string) => {
  switch (error) {
    case 'storage/unauthorized':
      return 'Unauthorized to upload a logo';
    case 'storage/canceled':
      return 'Logo upload to storage Canceled';
    case 'storage/unknown':
      return "Couldn't upload logo to storage for unknown reason";
    default:
      return 'Error occurred while uploading logo to storage';
  }
};

// API Call for Demo Creation of Organisation Start --->
const organisationCreation = (body: CreateDemoRequestBody) =>
  axios.post('/b2b/sales-demo/enterprises', body).then(res => res.data);
// <--- API Call for Demo Creation of Organisation End

function FirstStep({ setCurrentStep, setTempDomain }: Props) {
  const [loader, setLoader] = useState<boolean>(false);
  const { setToastType, userInfo } = useAuthContext()!;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateDemoForm>();

  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(organisationCreation, {
    onSuccess: data => {
      queryClient.invalidateQueries('demos');
      setTempDomain(data.domain);
      setCurrentStep('SECOND_STEP');
      setToastType({
        show: true,
        message: 'Enterprise Created',
        type: 'info',
      });
    },
    onError: error => {
      if (isErrorType(error)) {
        setToastType({
          show: true,
          message: 'Error Occurred while creating Enterprise Demo',
          type: 'error',
        });
      }
    },
  });

  const uploadOrgLogo = async (fileData: File, organisationName: string) => {
    setLoader(true);
    try {
      const { type } = fileData;
      const filePath = `sales-demo-enterprises/logos/${organisationName}-${new Date().toISOString()}_main.${
        type.split('/')[1]
      }`;
      await firebase.uploadFileToStorage(filePath, fileData);
      const fileURL = await firebase.getFileFromStorage(filePath);
      setLoader(false);
      return fileURL;
    } catch (error) {
      console.error(error);
      const errorMessage = getStorageErrorMessage(error.code);
      setToastType({
        show: true,
        message: errorMessage,
        type: 'error',
      });
      setLoader(false);
      return ' ';
    }
  };
  const createOrganisation = async ({
    organisationName,
    image,
  }: CreateDemoForm) => {
    const fileUrl =
      image && image.length > 0
        ? await uploadOrgLogo(image[0], organisationName)
        : '';
    if (fileUrl === ' ') return;

    const body: CreateDemoRequestBody = {
      enterprise_name: organisationName,
      logo: fileUrl,
      created_by: userInfo?.email || '',
    };
    // React Query Mutation
    mutate(body);
  };
  return (
    <FormWrapper onSubmit={handleSubmit(createOrganisation)}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <RayInput
            id="org_name"
            fullWidth
            label="Organisation Name"
            placeholder="WeWork"
            disabled={isLoading || loader}
            {...register('organisationName', {
              required: 'Organisation Name required to proceed',
              pattern: {
                value: /^[a-zA-Z ]*$/i,
                message:
                  "Organisation Name can't include special characters and numbers",
              },
            })}
          />
          {errors &&
          errors.organisationName &&
          errors.organisationName.message ? (
            <RayBodyText variant="small" errorText>
              {errors.organisationName.message}
            </RayBodyText>
          ) : (
            <RayBodyText variant="small">
              Name should only contain alphabets (aA-zZ) and spaces
            </RayBodyText>
          )}
        </Grid>
        <Grid item xs={12} container spacing={0} alignItems="center">
          <Grid item xs={12}>
            <H6 mb0>Upload Organisation Logo</H6>
          </Grid>
          <Grid item xs={12}>
            <RayBodyText greyText>
              Please upload in JPG or PNG formats. File size limit: upto 1MB
              each.
            </RayBodyText>
          </Grid>

          <Grid item xs={3}>
            <input
              type="file"
              disabled={isLoading || loader}
              {...register('image')}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} spacing={0} container justify="flex-end">
          <Grid item xs={3}>
            <RayButton type="submit" disabled={isLoading || loader} fullWidth>
              {loader || isLoading ? (
                <CircularProgress size={15} style={{ color: '#fff' }} />
              ) : (
                'Create'
              )}
            </RayButton>
          </Grid>
        </Grid>
      </Grid>
    </FormWrapper>
  );
}

export default FirstStep;
