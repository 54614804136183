import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

type Props = {
  children: React.ReactNode;
  onClick?:
    | ((event: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => void)
    | undefined;
  style?: React.CSSProperties;
  variant?: 'large' | 'regular' | 'small' | 'x-small';
  className?: string;
  mb0?: boolean;
  errorText?: boolean;
  greyText?: boolean;
};

const RAY_BODY_CLASS = 'ray-text--body';

function BodyText({
  onClick,
  style,
  children,
  className = '',
  variant = 'regular',
  mb0 = false,
  errorText = false,
  greyText = false,
}: Props) {
  const classes = useStyles();
  return (
    <span
      onClick={onClick}
      style={style}
      className={`${
        variant === 'regular' ? RAY_BODY_CLASS : RAY_BODY_CLASS + '-' + variant
      } ${className} ${mb0 ? classes.zeroMarginBottom : ''} ${
        errorText ? classes.errorText : ''
      } ${greyText ? classes.greyText : ''}`}
    >
      {children}
    </span>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    zeroMarginBottom: {
      marginBottom: '0 !important',
    },
    errorText: {
      color: '#CC0000',
    },
    greyText: {
      color: '#808080',
    },
  }),
);

export default BodyText;
