import React from 'react';
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { MdClose } from 'react-icons/md';
import { H3 } from '../RayTypography/Heading';
import BodyText from '../RayTypography/Body';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& h3': {
        width: '92%',
      },
      '& span': {
        width: '100%',
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      width: 'auto',
    },
  });
const dialogStyles = makeStyles((theme: Theme) => ({
  modalStyle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      maxHeight: '100%',
      height: '100%',
    },
  },
}));
export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  subtitle: string | undefined;
  onClose: () => void;
  closeBtnDisabled: boolean;
}
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const {
    children,
    classes,
    subtitle,
    closeBtnDisabled = false,
    onClose,
    ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <H3 mb0>{children}</H3>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          disabled={closeBtnDisabled}
        >
          <MdClose />
        </IconButton>
      ) : null}
      {subtitle && (
        <BodyText greyText variant="small" mb0>
          {subtitle}
        </BodyText>
      )}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function DialogModal({
  isOpen,
  children,
  showModal,
  title = 'Modal Title',
  maxWidth = 'sm',
  fullWidth = false,
  subtitle = undefined,
  disableBackdropClick = false,
  closeBtnDisabled = false,
  ...props
}: DialogProps) {
  const customClasses = dialogStyles();

  return (
    <Dialog
      onClose={() => showModal(false)}
      aria-labelledby={`${title}-dialog-title`}
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      className={customClasses.modalStyle}
      disableBackdropClick={disableBackdropClick}
      PaperProps={{
        classes: {
          root: customClasses.modalStyle,
        },
      }}
      {...props}
    >
      <DialogTitle
        id={`${title}-dialog-title`}
        onClose={() => showModal(false)}
        subtitle={subtitle}
        closeBtnDisabled={closeBtnDisabled}
      >
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

type DialogProps = {
  isOpen: boolean;
  children: React.ReactNode;
  showModal: (x: boolean) => void;
  title?: string;
  maxWidth?: DialogMaxWidth;
  fullWidth?: boolean;
  subtitle?: string | undefined;
  disableBackdropClick?: boolean;
  closeBtnDisabled?: boolean;
};

type DialogMaxWidth = 'lg' | 'sm' | 'md' | 'xl' | 'xs' | false;
