import { Dispatch, Children } from 'react';
import Grid from '@material-ui/core/Grid';
import { H5 } from 'src/components/RayTypography/Heading';
import RayBodyText from 'src/components/RayTypography/Body';
import { EMPLOYEE_TYPES } from 'src/utils/constants';
import EmployeeItem from '../EmployeeItem';

type Props = {
  setCurrentStep: Dispatch<string>;
  tempDomain: string;
};
function SecondStep({ setCurrentStep, tempDomain }: Props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <H5 mb0>Congratulations Enterprise Successfully Created</H5>
      </Grid>
      <Grid item xs={12}>
        <RayBodyText>Following Email can be used now:</RayBodyText>
      </Grid>
      <Grid item xs={12}>
        {tempDomain && tempDomain.length > 0
          ? Children.toArray(
              EMPLOYEE_TYPES.map(type => (
                <EmployeeItem type={type} domainName={tempDomain} />
              )),
            )
          : 'N/A'}
      </Grid>
    </Grid>
  );
}

export default SecondStep;
