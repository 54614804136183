import { Helmet } from 'react-helmet-async';
import { UserProvider } from 'src/utils/context';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import AuthenticatedRoute from 'src/components/AuthenticatedRoute';
import ToastAlert from 'src/components/ToastAlert';
import { NotFoundPage } from 'src/components/NotFoundPage';
import LoginPage from 'src/pages/LoginPage';
import DemosList from 'src/pages/DemosList';
import SalesRepsList from 'src/pages/SalesRepsList';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - WeWork Sales Admin"
        defaultTitle="WeWork Sales Admin"
      >
        <meta name="description" content="WeWork Sales Admin" />
      </Helmet>
      <UserProvider>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          {/* <AuthenticatedRoute exact path="/demos" component={RegisterPage} /> */}
          <AuthenticatedRoute exact path="/demos" component={DemosList} />
          <AuthenticatedRoute
            exact
            adminRoute
            path="/sales-reps"
            component={SalesRepsList}
          />
          <Redirect exact from="/" to="demos" />
          <Route component={NotFoundPage} />
        </Switch>
        <ToastAlert />
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
