import firebase from 'firebase/app';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

const configDev = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  auth: firebase.auth.Auth;
  storage: firebase.storage.Storage;
  constructor() {
    const config =
      process.env.NODE_ENV === 'development'
        ? configDev
        : JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!);
    app.initializeApp(config);
    this.auth = app.auth();
    this.storage = app.storage();
  }
  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  resetPasswordWithEmail = (email: string) =>
    this.auth.sendPasswordResetEmail(email, {
      url: `${process.env.REACT_APP_URL}`,
    });

  getFileFromStorage = async (path: string) =>
    await this.storage.ref(path).getDownloadURL();

  uploadFileToStorage = async (path: string, file: any) => {
    const storageRef = this.storage.ref();
    const fileRef = storageRef.child(path);
    return await fileRef.put(file);
  };

  doSignOut = () => this.auth.signOut();
}

const firebaseObj = new Firebase();

export default firebaseObj;
