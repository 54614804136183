import { Dispatch } from 'react';
export interface EnterpriseItem {
  enterprise_name: string;
  created_at: CreatedAt;
  created_by: string;
  domains: string[];
  logo_urls: LogoUrls;
}

export interface CreatedAt {
  _seconds: number;
  _nanoseconds: number;
}

export interface LogoUrls {
  desktop: string;
  main: string;
  mobile: string;
}

// Create Demo types Start --->
export interface CreateDemoForm{
  organisationName: string;
  image: FileList;
}
export interface CreateDemoProps {
  setCurrentStep: Dispatch<string>;
  setTempDomain: Dispatch<string>;
}
export interface CreateDemoRequestBody {
  enterprise_name: string;
  logo: string;
  created_by: string;
}
// <--- Create Demo types End

// Function for identifying React Query's Error Object
export function isErrorType(error: unknown): error is Error {
  return error instanceof Error;
}
