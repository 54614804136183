import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useAuthContext } from 'src/utils/hooks/useAuthContext';
import { makeStyles, Theme } from '@material-ui/core/styles';

const Wrapper = styled.aside`
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-right: 1.5px solid #dad9d9;
`;

const DisabledLink = styled(NavLink)`
  cursor: default;
  &:hover {
    cursor: default;
    text-decoration: none;
  }
`;

const SidebarItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 1em;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
`;

function Sidebar() {
  const classes = useStyles();

  const { userInfo } = useAuthContext()!;

  return (
    <Wrapper>
      {React.Children.toArray(
        SIDEBAR_ROUTES.map((item, index) =>
          item.disabled ? (
            <DisabledLink
              to={item.route}
              key={+index}
              onClick={e => e.preventDefault()}
            >
              <SidebarItem>{item.label}</SidebarItem>
            </DisabledLink>
          ) : !item.onlyAdmin ? (
            <NavLink
              key={+index}
              to={item.route}
              activeClassName={classes.activeLink}
            >
              <SidebarItem>{item.label}</SidebarItem>
            </NavLink>
          ) : userInfo && userInfo.role === 'admin' ? (
            <NavLink
              activeClassName={classes.activeLink}
              key={+index}
              to={item.route}
            >
              <SidebarItem>{item.label}</SidebarItem>
            </NavLink>
          ) : null,
        ),
      )}
    </Wrapper>
  );
}

export default Sidebar;

const SIDEBAR_ROUTES = [
  {
    label: 'Demos List',
    route: '/demos',
    disabled: false,
    onlyAdmin: false,
  },
  {
    label: 'Add Sales Rep',
    route: '/sales-reps',
    disabled: false,
    onlyAdmin: true,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  activeLink: {
    color: '#0000FF !important',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
}));
