import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

type Props = {
  id?: string;
  value?: any;
  label?: React.ReactNode;
  onChange?:
    | ((
        event: React.ChangeEvent<{
          name?: string | undefined;
          value: unknown;
        }>,
        child: React.ReactNode,
      ) => void)
    | undefined;
  name?: string | undefined;
  inputRef?: React.Ref<any>;
  className?: string;
  placeholder?: string | undefined;
  options?: OptionProp[];
  disabled?: boolean | undefined;
  fullWidth?: boolean;
};

interface OptionProp {
  name?: string | undefined;
  value?: string | number | undefined;
}

function NativeSelect({
  id = `native_Select${new Date().toTimeString()}`,
  value,
  label,
  onChange,
  name,
  inputRef,
  className,
  placeholder,
  disabled = false,
  options = [],
  fullWidth = false,
}: Props) {
  return (
    <FormControl fullWidth={fullWidth} variant="outlined" className={className}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        native
        value={value}
        onChange={onChange}
        label={label}
        name={name}
        fullWidth={fullWidth}
        placeholder={placeholder}
        inputRef={inputRef}
        disabled={disabled}
        inputProps={{
          name: name,
          id: id,
        }}
      >
        <option aria-label="None" value="" />
        {options.length > 0 &&
          React.Children.toArray(
            options.map(option => (
              <option value={option.value}>{option.name}</option>
            )),
          )}
      </Select>
    </FormControl>
  );
}
export default NativeSelect;
