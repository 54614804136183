import { Children } from 'react';
import { format as dateFormat } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import RayBodyText from 'src/components/RayTypography/Body';
import { EMPLOYEE_TYPES } from 'src/utils/constants';
import { EnterpriseItem } from '../types';
import EmployeeItem from '../EmployeeItem';

type Props = {
  selectedDemo: EnterpriseItem;
  gotoB2BApp: () => void;
};

function DemosInfo({ selectedDemo, gotoB2BApp }: Props) {
  const { enterprise_name, domains, created_at } = selectedDemo;
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <RayBodyText>
          <b>Enterprise Name: </b>
          {enterprise_name}
        </RayBodyText>
      </Grid>
      <Grid item xs={6}>
        <RayBodyText>
          <b>Domain: </b>
          {domains && domains.length > 0 ? domains[0] : '--'}
        </RayBodyText>
      </Grid>
      <Grid item xs={6}>
        <RayBodyText>
          <b>Created At: </b>
          {created_at && created_at._seconds
            ? dateFormat(new Date(created_at._seconds * 1000), 'do MMM, yyy')
            : '--'}
        </RayBodyText>
      </Grid>
      <Grid item xs={6}>
        <RayBodyText>
          <b>Enterprise Emails: </b>
        </RayBodyText>
        <br />
        {domains && domains.length > 0
          ? Children.toArray(
              EMPLOYEE_TYPES.map(type => (
                <EmployeeItem type={type} domainName={domains[0]} />
              )),
            )
          : 'N/A'}
      </Grid>
    </Grid>
  );
}

export default DemosInfo;
