import React from 'react';
import styled from 'styled-components/macro';
import WeWorkLogo from 'src/assets/wework_main_logo.png';
import RayButton from 'src/components/RayButton';
import firebase from 'src/utils/firebase';

const Wrapper = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 2.5em;
  width: 100%;
  background-color: #f3f6f9;

  button {
    width: 10%;
  }

  .btn-wrapper {
    display: flex;
    justify-content: space-evenly;
  }
`;

const Logo = styled.img`
  height: 2em;
`;

function Header() {
  return (
    <Wrapper>
      <Logo src={WeWorkLogo} alt="" />
      <div className="btn-wrapper">
        <RayButton
          onClick={() => firebase.doSignOut()}
          fullWidth
          buttonType="tertiary"
        >
          Logout
        </RayButton>
      </div>
    </Wrapper>
  );
}

export default Header;
