import styled from 'styled-components/macro';

const RouteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;
  flex-wrap: wrap;
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  min-height: 95vh;
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  width : 100%;
  min-height: 95vh;
  overflow: auto;
  padding: 1em;
  margin-top: 1em;
`;

export { RouteWrapper, MainSection, Page };
