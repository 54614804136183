import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Loader from 'src/components/SectionLoader';
import AuthWrapper from './AuthWrapper';
import RayButton from 'src/components/RayButton';
import RayInput from 'src/components/RayInput';
import RayBodyText from 'src/components/RayTypography/Body';
import { useAuthContext } from 'src/utils/hooks/useAuthContext';
import firebase from 'src/utils/firebase';
import axios from 'src/utils/axios';
import logo from 'src/assets/wework_main_logo.png';
import { ERROR_MESSAGES_FIREBASE, LoginFormData, AuthPageType } from './types';

function Login({ toggleRegisterMode }: AuthPageType) {
  const classes = useStyles();

  const { setToastType, userInfo, tokenLoad } = useAuthContext()!;

  const { push: changeRoute } = useHistory();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<LoginFormData>();

  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (userInfo && userInfo.firebaseUserId) changeRoute('/demos');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const onSubmit = async (data: LoginFormData) => {
    setLoader(true);
    await verifyUser(data);
  };
  const verifyUser = async ({ userEmail, password }: LoginFormData) => {
    try {
      const verifyUser = await axios.post('/b2b/sales-demo/users/login', {
        email: userEmail,
      });
      if (verifyUser.data.is_registered)
        await loginWithFirebase(userEmail, password);
      else {
        setLoader(false);
        setToastType({
          show: true,
          message: 'Please signup first to login',
          type: 'error',
        });
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
      setToastType({
        show: true,
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Error occurred while verifying user',
        type: 'error',
      });
    }
  };
  const loginWithFirebase = async (userEmail: string, password: string) => {
    try {
      const response = await firebase.doSignInWithEmailAndPassword(
        userEmail,
        password,
      );
      if (response.user) changeRoute('/demos');
      else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
      setToastType({
        show: true,
        message: ERROR_MESSAGES_FIREBASE[error.code],
        type: 'error',
      });
    }
  };
  const resetPassword = async () => {
    const loginEmail = getValues()['userEmail'];
    if (getValues()['userEmail'].length < 3) {
      setToastType({
        show: true,
        message: ERROR_MESSAGES_FIREBASE['auth/invalid-email'],
        type: 'error',
      });
    } else {
      try {
        await firebase.resetPasswordWithEmail(loginEmail);
        setToastType({
          show: true,
          message: `A password reset link has been sent to ${loginEmail}`,
          type: 'success',
        });
      } catch (e) {
        console.error(e);
        setToastType({
          show: true,
          message: ERROR_MESSAGES_FIREBASE[e.code],
          type: 'error',
        });
      }
    }
  };

  if (tokenLoad) return <Loader />;
  return (
    <AuthWrapper onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <img
            className="wework-logo"
            src={logo}
            alt="wework logo"
            //   onClick={() => eventRecorder("NO")}
          />
        </Grid>
        <Grid item md={10} xs={12}>
          <RayInput
            id="login_email"
            label="Email Address"
            placeholder="arya@wework.co.in"
            type="email"
            fullWidth
            {...register('userEmail', {
              required: 'This field is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Enter a valid email address',
              },
            })}
          />
          {errors.userEmail && (
            <RayBodyText variant="x-small" errorText>
              {errors.userEmail.message}
            </RayBodyText>
          )}
        </Grid>
        <Grid item md={10} xs={12}>
          <RayInput
            id="login_password"
            label="Password"
            placeholder="Password"
            type="password"
            fullWidth
            {...register('password', {
              required: 'Password is required to login',
              minLength: {
                value: 6,
                message: 'Password should contain atleast 6 characters',
              },
            })}
          />
          {errors.password && (
            <RayBodyText variant="x-small" errorText>
              {`${errors.password.message} `}
            </RayBodyText>
          )}
          <RayBodyText
            className={classes.forgotPassword}
            onClick={resetPassword}
            variant="x-small"
          >
            Forgot Password?
          </RayBodyText>
        </Grid>
        <Grid item md={4} xs={7}>
          <RayButton
            disabled={loader}
            fullWidth
            type="submit"
            buttonType="primary"
            compact
            className={classes.buttonMb}
          >
            {loader ? (
              <CircularProgress size={20} className={classes.whiteColor} />
            ) : (
              <span>Login</span>
            )}
          </RayButton>
          <RayBodyText
            className={classes.toggleMode}
            onClick={() => toggleRegisterMode(true)}
          >
            Register
          </RayBodyText>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
}

export default Login;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonMb: { marginBottom: '2em' },
    whiteColor: { color: '#fff' },
    toggleMode: {
      fontWeight: 'bolder',
      marginTop: '2em',
      textAlign: 'center',
      color: '#0000FF',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
    forgotPassword: {
      color: '#0000FF',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  }),
);
