import { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DialogModal from 'src/components/DialogModal';
import RayButton from 'src/components/RayButton';
import TableContainer from './TableContainer';
import CreateRepresentative from './CreateRepresentative';

function SalesRepsList() {
  const [showCreateUserDialog, toggleCreateUserDialog] =
    useState<boolean>(false);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          <RayButton fullWidth onClick={() => toggleCreateUserDialog(true)}>
            Create User
          </RayButton>
        </Grid>
        <Grid item xs={12}>
          <TableContainer />
        </Grid>
      </Grid>
      <DialogModal
        isOpen={showCreateUserDialog}
        showModal={toggleCreateUserDialog}
        title="Create a Sales Rep."
        maxWidth="sm"
        fullWidth
      >
        <CreateRepresentative toggleCreateUserDialog={toggleCreateUserDialog} />
      </DialogModal>
    </>
  );
}

export default SalesRepsList;
