import { Children, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import axios from 'src/utils/axios';
import styled from 'styled-components/macro';
import { useAuthContext } from 'src/utils/hooks/useAuthContext';
import DialogModal from 'src/components/DialogModal';
import SectionLoader from 'src/components/SectionLoader';
import RayButton from 'src/components/RayButton';
import { format as dateFormat } from 'date-fns';
import DemoInfo from './DemoInfo';
import { EnterpriseItem } from './types';
import FeedBackIcon from 'src/assets/feedback_icon.png';
import { Grid } from '@material-ui/core';

const Wrapper = styled.div`
  width: 100%;
  table {
    font-size: 14px;
    width: 100%;
  }

  .btn-row {
    display: flex;
  }

  .feedback-btn-grid {
    display: flex;
    align-items: center;
  }

  .feedback-btn-text {
    padding-left: 5px;
    padding-bottom: 3px;
  }
`;

const fetchDemosList = (email: string) =>
  axios
    .get('/b2b/sales-demo/users/enterprises', {
      params: {
        email,
      },
    })
    .then(res => res.data);

function TableContainer() {
  const { setToastType, userInfo } = useAuthContext()!;
  const {
    isLoading,
    error,
    data: demosList,
  } = useQuery(
    ['demos', userInfo?.email],
    () => fetchDemosList(userInfo?.email ? userInfo.email : ''),
    { enabled: !!userInfo?.email },
  );
  const [showInfoDialog, toggleInfoDialog] = useState<boolean>(false);
  const [selectedDemo, setSelectedDemo] =
    useState<EnterpriseItem | undefined>();

  useEffect(() => {
    if (error) {
      setToastType({
        show: true,
        type: 'error',
        message: 'Error occurred while fetching demos list',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, demosList]);
  const demoSelection = (item: EnterpriseItem) => {
    setSelectedDemo(item);
    toggleInfoDialog(true);
  };
  const gotoB2BApp = () => {
    window.open(process.env.REACT_APP_B2B_APP_URL, '_blank')?.focus();
  };
  if (isLoading) return <SectionLoader />;
  return (
    <>
      <Wrapper>
        <table className="ray-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Domain</th>
              <th>Created At</th>
              <th style={{ width: '25%' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {demosList &&
              demosList.enterprises &&
              demosList.enterprises.length > 0 &&
              Children.toArray(
                demosList.enterprises.map((cell: EnterpriseItem) => (
                  <tr className="booking_row">
                    <td>
                      {cell.enterprise_name ? cell.enterprise_name : '--'}
                    </td>
                    <td>
                      {cell.domains && cell.domains.length > 0
                        ? cell.domains[0]
                        : '--'}
                    </td>
                    <td>
                      {cell.created_at && cell.created_at._seconds
                        ? dateFormat(
                            new Date(cell.created_at._seconds * 1000),
                            'do MMM, yyy',
                          )
                        : '--'}
                    </td>
                    <td className="btn-row">
                      <RayButton compact onClick={gotoB2BApp}>
                        Test Demo
                      </RayButton>
                      <RayButton
                        buttonType="secondary"
                        compact
                        onClick={() => demoSelection(cell)}
                      >
                        View Details
                      </RayButton>
                      <RayButton
                        buttonType="tertiary"
                        compact
                        onClick={() =>
                          window.open(
                            'https://forms.gle/pq74Ym1KDFN6QCDu8',
                            '__blank',
                          )
                        }
                      >
                        <Grid container>
                          <Grid item className="feedback-btn-grid">
                            <img src={FeedBackIcon} alt="feedbackicon" />
                            <p className="feedback-btn-text">Feedback</p>
                          </Grid>
                        </Grid>
                      </RayButton>
                    </td>
                  </tr>
                )),
              )}
          </tbody>
        </table>
      </Wrapper>
      {selectedDemo?.enterprise_name && (
        <DialogModal
          isOpen={showInfoDialog}
          showModal={toggleInfoDialog}
          title={selectedDemo?.enterprise_name}
          maxWidth="md"
          fullWidth
        >
          <DemoInfo selectedDemo={selectedDemo} gotoB2BApp={gotoB2BApp} />
        </DialogModal>
      )}
    </>
  );
}

export default TableContainer;
