import styled from 'styled-components/macro';

const AuthWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #000;
  background-color: #fff;
  padding: 1em 5em;

  img.wework-logo {
    width: 10em;
  }
  @media (min-width: 320px) and (max-width: 1024px) {
    min-height: 70vh;
    padding: 2em 2em;
    justify-content: flex-start;
  }
`;

export default AuthWrapper;
