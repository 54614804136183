import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthContext } from 'src/utils/hooks/useAuthContext';
import Loader from '../SectionLoader';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { RouteWrapper, MainSection, Page } from './styles';

type Props = {
  exact?: boolean;
  component: React.ComponentType<any>;
  path: string;
  adminRoute?: boolean;
};

function AuthenticatedRoute({
  component: Component,
  adminRoute = false,
  path,
  ...rest
}: Props) {
  const { tokenLoad, userInfo } = useAuthContext()!;

  const page = (
    <RouteWrapper>
      <Header />
      <Sidebar />
      <Page>
        <MainSection>
          <Component {...rest} />
        </MainSection>
      </Page>
    </RouteWrapper>
  );
  if (tokenLoad) return <Loader />;
  if (userInfo && !userInfo.firebaseUserId) return <Redirect to="/login" />;
  if (adminRoute) {
    if (userInfo && userInfo.firebaseUserId && userInfo.role === 'admin')
      return <Route {...rest} render={() => page} />;
    return <Route {...rest} render={() => <Redirect to="/demos" />} />;
  }
  if (userInfo && userInfo.firebaseUserId)
    return <Route {...rest} render={() => page} />;
  return <Route {...rest} render={() => <Redirect to="/login" />} />;
}

export default AuthenticatedRoute;
