import { Children, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import axios from 'src/utils/axios';
import styled from 'styled-components/macro';
import { useAuthContext } from 'src/utils/hooks/useAuthContext';
import DialogModal from 'src/components/DialogModal';
import SectionLoader from 'src/components/SectionLoader';
import RayButton from 'src/components/RayButton';
import { format as dateFormat } from 'date-fns';
import RepInfo from './RepInfo';
import { SalesRepresentative } from './types';

const Wrapper = styled.div`
  width: 100%;
  table {
    font-size: 14px;
    width: 100%;
  }
`;

const fetchSalesReps = (email: string) =>
  axios
    .get('/b2b/sales-demo/admin/users', {
      params: {
        email,
      },
    })
    .then(res => res.data);

function TableContainer() {
  const { setToastType, userInfo } = useAuthContext()!;

  const {
    isLoading,
    error,
    data: demosList,
  } = useQuery(
    ['salesReps', userInfo?.email],
    () => fetchSalesReps(userInfo?.email ? userInfo.email : ''),
    { enabled: !!userInfo?.email },
  );
  const [showInfoDialog, toggleInfoDialog] = useState<boolean>(false);
  const [selectedRepresentative, setSelectedRepresentative] =
    useState<SalesRepresentative | undefined>();

  useEffect(() => {
    console.log(demosList);
    if (error) {
      setToastType({
        show: true,
        type: 'error',
        message: 'Error occurred while fetching demos list',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, demosList]);
  const representativeSelection = (item: SalesRepresentative) => {
    setSelectedRepresentative(item);
    toggleInfoDialog(true);
  };
  if (isLoading) return <SectionLoader />;
  return (
    <>
      <Wrapper>
        <table className="ray-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Created At</th>
              <th style={{ width: '15%' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {demosList &&
              demosList.sales_users &&
              demosList.sales_users.length > 0 &&
              Children.toArray(
                demosList.sales_users.map((cell: SalesRepresentative) => (
                  <tr className="booking_row">
                    <td>{cell.name ? cell.name : '--'}</td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {cell.role ? cell.role : 'N/A'}
                    </td>
                    <td>
                      {cell.created_at && cell.created_at._seconds
                        ? dateFormat(
                            new Date(cell.created_at._seconds * 1000),
                            'do MMM, yyy',
                          )
                        : '--'}
                    </td>
                    <td>
                      <RayButton
                        buttonType="secondary"
                        compact
                        onClick={() => representativeSelection(cell)}
                      >
                        View Details
                      </RayButton>
                    </td>
                  </tr>
                )),
              )}
          </tbody>
        </table>
      </Wrapper>
      {selectedRepresentative?.name && (
        <DialogModal
          isOpen={showInfoDialog}
          showModal={toggleInfoDialog}
          title="Sales Rep Info"
          maxWidth="sm"
          fullWidth
        >
          <RepInfo selectedRep={selectedRepresentative} />
        </DialogModal>
      )}
    </>
  );
}

export default TableContainer;
