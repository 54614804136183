import { useState } from 'react';
import styled from 'styled-components/macro';
import Grid from '@material-ui/core/Grid';
import DialogModal from 'src/components/DialogModal';
import { H3 } from 'src/components/RayTypography/Heading';
import { BsArrowRight } from 'react-icons/bs';
import TableContainer from './TableContainer';
import { FirstStep, SecondStep } from './CreateDemo';

const CreateDemoButton = styled.div`
  width: 100%;
  border-radius: 5px;
  border: 3px solid #0000ff;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5em;
  color: #0000ff;

  &:hover {
    cursor: pointer;
    color: #fff;
    background-color: #0000ff;
  }
  svg {
    font-size: 2em;
  }
`;

function DemosList() {
  const [showCreateDialog, toggleCreateDialog] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<string>('FIRST_STEP');
  const [tempDomain, setTempDomain] = useState<string>('');

  const handleChangeDialog = (value: boolean) => {
    toggleCreateDialog(value);
    setCurrentStep('FIRST_STEP');
    setTempDomain('');
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <H3>Demos List</H3>
        </Grid>
        <Grid item xs={2}>
          <CreateDemoButton onClick={() => handleChangeDialog(true)}>
            <H3>Create a new Demo</H3>
            <BsArrowRight />
          </CreateDemoButton>
        </Grid>
        <Grid item xs={12}>
          <TableContainer />
        </Grid>
      </Grid>
      <DialogModal
        isOpen={showCreateDialog}
        showModal={handleChangeDialog}
        title={
          currentStep === 'FIRST_STEP' ? 'Create a new Demo' : 'Demo Created'
        }
        maxWidth="sm"
        fullWidth
      >
        {currentStep === 'FIRST_STEP' && (
          <FirstStep
            setCurrentStep={setCurrentStep}
            setTempDomain={setTempDomain}
          />
        )}
        {currentStep === 'SECOND_STEP' && (
          <SecondStep setCurrentStep={setCurrentStep} tempDomain={tempDomain} />
        )}
      </DialogModal>
    </>
  );
}

export default DemosList;
