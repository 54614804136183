import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from 'src/utils/firebase';
import axios from 'src/utils/axios';
import { useAuthContext } from 'src/utils/hooks/useAuthContext';
import RayInput from 'src/components/RayInput';
import RayButton from 'src/components/RayButton';
import RayBodyText from 'src/components/RayTypography/Body';
import logo from 'src/assets/wework_main_logo.png';
import { SignUpFormData, ERROR_MESSAGES_FIREBASE, AuthPageType } from './types';
import AuthWrapper from './AuthWrapper';

function SignUp({ toggleRegisterMode }: AuthPageType) {
  const classes = useStyles();

  const { setToastType } = useAuthContext()!;

  const { push: changeRoute } = useHistory();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<SignUpFormData>();

  const [loader, setLoader] = React.useState<boolean>(false);

  const onSubmit = async (data: SignUpFormData) => {
    setLoader(true);
    await verifyUser(data);
  };

  const verifyUser = async ({ userEmail, password }: SignUpFormData) => {
    try {
      const verifyUser = await axios.post('/b2b/sales-demo/users/login', {
        email: userEmail,
      });
      if (!verifyUser.data.is_registered)
        await signUpWithFirebase(userEmail, password);
      else {
        setToastType({
          show: true,
          message: 'Already Registered please Login',
          type: 'error',
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
      setToastType({
        show: true,
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Error occurred while signing up the user',
        type: 'error',
      });
    }
  };

  const signUpWithFirebase = async (userEmail: string, password: string) => {
    try {
      const response = await firebase.doCreateUserWithEmailAndPassword(
        userEmail,
        password,
      );
      if (response.user) {
        await registerUserOnDB(userEmail, response.user.uid);
      } else {
        setLoader(false);
        // dispatch(actions.triggerSignOut());
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
      setToastType({
        show: true,
        message: ERROR_MESSAGES_FIREBASE[error.code],
        type: 'error',
      });
    }
  };

  const registerUserOnDB = async (email: string, firebaseUserId: string) => {
    try {
      const requestBody = {
        firebaseUserId,
        email,
      };
      await axios.post('/b2b/sales-demo/users/register', requestBody);
      changeRoute('/');
    } catch (error) {
      console.error(error);
      setLoader(false);
      setToastType({
        show: true,
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Error occurred while registering the user',
        type: 'error',
      });
    }
  };

  return (
    <AuthWrapper onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <img className="wework-logo" src={logo} alt="wework logo" />
        </Grid>
        <Grid item md={10} xs={12}>
          <RayInput
            id="signup_email"
            label="Email Address"
            placeholder="arya@wework.co.in"
            type="email"
            fullWidth
            {...register('userEmail', {
              required: 'This field is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Enter a valid email address',
              },
            })}
          />
          {errors.userEmail && (
            <RayBodyText variant="x-small" errorText>
              {errors.userEmail.message}
            </RayBodyText>
          )}
        </Grid>
        <Grid item md={10} xs={12}>
          <RayInput
            id="signup_password"
            label="Password"
            placeholder="Password"
            fullWidth
            type="password"
            {...register('password', {
              required: 'Password is required to signup',
              minLength: {
                value: 6,
                message: 'Password should contain atleast 6 characters',
              },
            })}
          />

          {errors.password && (
            <RayBodyText variant="x-small" errorText>
              {errors.password.message}
            </RayBodyText>
          )}
        </Grid>
        <Grid item md={10} xs={12}>
          <RayInput
            id="confirm_password"
            label="Confirm"
            placeholder="Confirm Password"
            type="password"
            fullWidth
            {...register('confirmPassword', {
              required: 'You need to confirm your password',
              validate: value => {
                return value === getValues()['password']
                  ? true
                  : 'The passwords do not match';
              },
            })}
          />
          {errors.confirmPassword && (
            <RayBodyText variant="x-small" errorText>
              {errors.confirmPassword.message}
            </RayBodyText>
          )}
        </Grid>

        <Grid item md={4} xs={7}>
          <RayButton
            compact
            disabled={loader}
            type="submit"
            buttonType="primary"
            fullWidth
            className={classes.buttonMb}
          >
            {loader ? (
              <CircularProgress size={20} style={{ color: '#FFFFFF' }} />
            ) : (
              <span>Sign Up</span>
            )}
          </RayButton>
          <RayBodyText
            className={classes.toggleMode}
            onClick={() => toggleRegisterMode(false)}
          >
            Login
          </RayBodyText>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
}

export default SignUp;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toggleMode: {
      fontWeight: 'bolder',
      marginTop: '2em',
      color: '#0000FF',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
    buttonMb: { marginBottom: '2em' },
  }),
);
