import { useState, useEffect } from 'react';
import RayBodyText from 'src/components/RayTypography/Body';
import styled from 'styled-components/macro';
import Grid from '@material-ui/core/Grid';
import { AiOutlineCopy } from 'react-icons/ai';
import { FiExternalLink } from 'react-icons/fi';
import { HiOutlineCheck } from 'react-icons/hi';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import gotoB2B from 'src/utils/functions/gotoB2B';

const EmployeeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5em;
  svg {
    font-size: 1.2em;
    color: #0000ff;
    &:hover {
      cursor: pointer;
    }
  }
  svg.check-btn {
    color: #12872A;
    font-weight: bolder;
  }
  svg.copy-btn {
    color: #808080;
    &:hover {
      cursor: pointer;
    }
  }
`;
type Props = {
  domainName: string;
  type: string;
};

function EmployeeItem({ domainName, type }: Props) {
  const [copied, setCopied] = useState<boolean>(false);
  const employeeEmail = `${type}@${domainName}`;

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <EmployeeWrapper>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={9}>
          <RayBodyText mb0>{employeeEmail}</RayBodyText>
        </Grid>
        <Grid item xs={2}>
          <FiExternalLink onClick={gotoB2B} />
        </Grid>
        <Grid item xs={1}>
          <CopyToClipboard
            text={employeeEmail}
            onCopy={(text, value) => setCopied(value)}
          >
            {copied ? (
              <HiOutlineCheck className="check-btn" />
            ) : (
              <AiOutlineCopy className="copy-btn" />
            )}
          </CopyToClipboard>
        </Grid>
      </Grid>
    </EmployeeWrapper>
  );
}

export default EmployeeItem;
